import { AcompanhamentoCidadaosVinculadosFilterInput } from 'graphql/types.generated'

import { AcompanhamentoCidadaosVinculadosFilterModel } from './components/AcompanhamentoCidadaosVinculadosFilter'
import { AcompanhamentoCidadaosVinculadosQueryModel, AcompanhamentoCidadaosVinculadosRowModel } from './model'

export function convertAcompanhamentoCidadaosVinculadosFilterModelToFilterInput(
  filter: AcompanhamentoCidadaosVinculadosFilterModel
): AcompanhamentoCidadaosVinculadosFilterInput {
  return {
    equipeResponsavelId: filter?.equipe?.id,
    microareas: filter?.microareas,
    pageParams: filter?.pageParams,
  }
}

export function convertAcompanhamentoCidadaosVinculadosQueryModelToRowModel(
  acompanhamento: AcompanhamentoCidadaosVinculadosQueryModel
): AcompanhamentoCidadaosVinculadosRowModel {
  return {
    dataNascimento: acompanhamento.dataNascimentoCidadao,
    idade: acompanhamento.idade,
    nome: acompanhamento.nomeCidadao,
    sexoIdentidadeGenero: acompanhamento.sexoIdentidadeGeneroCidadao,
    cns: acompanhamento.cnsCidadao,
    cpf: acompanhamento.cpfCidadao,
    dataUltimaAtualizacaoCadastral: acompanhamento.dataUltimaAtualizacaoCidadao,
    endereco: acompanhamento.endereco,
    telefone: {
      telefoneCelular: acompanhamento.telefoneCelular,
      telefoneContato: acompanhamento.telefoneContato,
      telefoneResidencial: acompanhamento.telefoneResidencial,
    },
    microarea: acompanhamento.microarea,
    equipe: acompanhamento.equipe,
    origem: acompanhamento.origem,
  }
}
