/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Checkbox, Grid, HFlow, TableFooter, Theme, useTheme } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { useLembretesModalQuery } from 'graphql/hooks.generated'
import { useFilter } from 'hooks/filter/useFilter'
import { useMemo, useState } from 'react'
import { LembreteFilterModel, LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { useAccordionControl } from '../../../../../../components/accordion/useAccordionControl'
import { usePagination } from '../../../../../../components/table/usePagination'
import LembretesAccordionPanel from './LembretesAccordionPanel'
import { LembreteTableRow } from './LembreteTableRow'
import { filterMostrarInativos } from './ListaLembretesFilter'
import { mergeSortLembretes } from './mergeSortLembretes'
import { renderDescricaoLembrete, renderInativoLembrete, renderVisibilidadeLembrete } from './render'

interface ReadOnlyListaLembretesModalProps {
  prontuarioId: ID
  lotacao: LotacaoAtendimento
}

export function ReadOnlyListaLembretesModal(props: ReadOnlyListaLembretesModalProps) {
  const { prontuarioId, lotacao } = props

  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const [somenteMeus, setSomenteMeus] = useState<boolean>(false)

  const { data, loading } = useLembretesModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lembretesQueryInput: {
        prontuarioId,
        somenteMeus,
      },
    },
  })

  const lembretesDatabase = useMemo(() => mergeSortLembretes(data?.lembretes ?? [], [], lotacao), [data, lotacao])

  const [filter, setFilter] = useState<LembreteFilterModel>({ mostrarInativos: false })

  const lembretesFiltered = useFilter<LembreteModel, LembreteFilterModel>({
    items: lembretesDatabase,
    filter: filter,
    filtersType: [],
    customFilters: [filterMostrarInativos(filter.mostrarInativos)],
  })

  const { paginatedItems, tableProps } = usePagination<LembreteModel>({
    items: lembretesFiltered,
    onChange: resetExpandedItems,
  })

  return (
    <Grid style={classes.grid}>
      <Cell size={12}>
        <HFlow style={classes.checkboxContainer}>
          <Checkbox
            label='Ver somente os lembretes criados por mim'
            onChange={() => {
              setSomenteMeus(!somenteMeus)
            }}
          />
          <Checkbox
            label='Mostrar lembretes inativos'
            onChange={() => {
              setFilter({ mostrarInativos: !filter.mostrarInativos })
            }}
          />
        </HFlow>
      </Cell>
      <Cell size={12}>
        <AccordionDataTable<LembreteModel>
          {...accordionProps}
          columns={[
            {
              name: 'descricao',
              header: 'Descrição',
              render: renderDescricaoLembrete,
              size: 12,
            },
            {
              name: 'inativo',
              header: '',
              render: renderInativoLembrete,
              size: 3,
            },
            {
              name: 'visibilidade',
              header: 'Visibilidade',
              render: renderVisibilidadeLembrete,
              size: 5,
            },
          ]}
          rows={paginatedItems}
          components={{
            AccordionPanel: LembretesAccordionPanel,
            Row: (props) => <LembreteTableRow {...props} />,
          }}
          loading={loading}
        />
        <TableFooter {...tableProps} style={classes.footer} />
      </Cell>
    </Grid>
  )
}

const styles = (theme: Theme) => ({
  footer: css`
    border-top: 0rem;
    margin-top: -0.1rem;
    padding-top: 0.1rem;
  `,
  checkboxContainer: css`
    border: 1px solid ${theme.pallete.divider};
    margin-bottom: -1.05rem;
    padding: 0.6rem 0.9rem;
  `,
  grid: css`
    margin-top: 0.5rem;
  `,
})
