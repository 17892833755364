import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { useCidadaoInformacoesContatoQuery } from 'graphql/hooks.generated'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import {
  assinaturaDigitalCancelMessage,
  defaultAssinaturaDigitalErrorMessage,
  DocumentoDigitalErrorCodeEnum,
  documentoDigitalErrorCodeToMessage,
  TipoDocumentoAssinaturaDigitalPopup,
} from 'hooks/assinatura-digital/model-assinaturaDigitalPopup'
import { useAssinaturaDigitalPopup } from 'hooks/assinatura-digital/useAssinaturaDigitalPopup'
import { useSessionHasConselhoClasse } from 'hooks/conselho-classe/useSessionHasConselhoClasse'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'

import { tituloAtestado } from '../atestadoUtils'
import { AtestadoContentBox } from '../components/AtestadoContentBox'
import AtestadoModel from '../components/model-atestado'
import { AtestadoRecenteImpressaoInput } from '../model-atestado'
import { AtestadoConfirmarAssinaturaDigitalModal } from './AtestadoConfirmarAssinaturaDigitalModal'
import { AtestadoOnSubmitCallback } from './model-atestadoAssinaturaDigital'

interface AtestadoAssinaturaDigitalDataToSubmit {
  values: AtestadoModel
  formApi: FormApi<AtestadoModel>
}

interface AtestadoAssinaturaDigitalFormResult {
  emailCidadao: string
  handleSubmitWrapper: AtestadoOnSubmitCallback
  isAssinando: boolean
}

interface AtestadoAssinaturaDigitalFormProps {
  cidadaoId: ID
  atendimentoId: ID
  prontuarioId: ID
  tipoAtestado: TipoAtestadoEnum
  dataAtendimento: Instant
  onSubmit: AtestadoOnSubmitCallback
}

export function useAtestadoAssinaturaDigitalForm(
  props: AtestadoAssinaturaDigitalFormProps
): AtestadoAssinaturaDigitalFormResult {
  const { cidadaoId, atendimentoId, prontuarioId, tipoAtestado, dataAtendimento, onSubmit } = props

  const sessionHasConselhoClasse = useSessionHasConselhoClasse()
  const alert = useAlert()

  const [isAssinando, setIsAssinando] = useState(false)
  const [isReadyToSubmitAtestadoAfterAssinatura, setIsReadyToSubmitAtestadoAfterAssinatura] = useState(false)
  const [dataIdentifier, setDataIdentifier] = useState<string>(null)
  const [valuesToSubmit, setValuesToSubmit] = useState<AtestadoAssinaturaDigitalDataToSubmit>(null)

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const emailCidadao = infoCidadao?.contatoCidadao?.email

  const { data, openPopup, closePopup } = useAssinaturaDigitalPopup<AtestadoRecenteImpressaoInput>(
    {
      atendimentoId: atendimentoId,
      cidadaoId: cidadaoId,
      emailCidadao: emailCidadao,
    },
    TipoDocumentoAssinaturaDigitalPopup.ATESTADO
  )

  const { analytics } = useFirebase()

  if (!isAssinando) {
    closePopup()
  }

  if (valuesToSubmit && isReadyToSubmitAtestadoAfterAssinatura) {
    onSubmit(valuesToSubmit.values, valuesToSubmit.formApi)
    setValuesToSubmit(null)
    setIsReadyToSubmitAtestadoAfterAssinatura(false)
  }

  const handleError = useCallback(
    (error: DocumentoDigitalErrorCodeEnum) => {
      if (error) {
        alert('danger', documentoDigitalErrorCodeToMessage[error])
      } else {
        alert('danger', defaultAssinaturaDigitalErrorMessage)
      }

      setIsAssinando(false)
      setValuesToSubmit(null)
    },
    [alert]
  )

  const handleCancel = useCallback(() => {
    setIsAssinando(false)
    setValuesToSubmit(null)
    alert('danger', assinaturaDigitalCancelMessage)
  }, [alert])

  useEffect(() => {
    if (isAssinando && data?.identifier !== dataIdentifier) {
      if (data?.status === 'success') {
        setIsAssinando(false)
        setIsReadyToSubmitAtestadoAfterAssinatura(true)
      } else if (data?.status === 'canceled') {
        handleCancel()
      } else if (data?.status === 'failure') {
        handleError(data.error)
      }
    }
    setDataIdentifier(data?.identifier)
  }, [data, dataIdentifier, handleCancel, handleError, isAssinando])

  const handleSubmitWrapper = async (values: AtestadoModel, formApi: FormApi<AtestadoModel>) => {
    let confirmed = false
    if (values.assinadoDigitalmente) {
      if (!sessionHasConselhoClasse) {
        alert('danger', documentoDigitalErrorCodeToMessage[DocumentoDigitalErrorCodeEnum.PROFISSIONAL_SEM_CONSELHO])
      } else {
        confirmed = await confirm({
          size: 'large',
          render: () => (
            <AtestadoConfirmarAssinaturaDigitalModal emailCidadao={emailCidadao}>
              <AtestadoContentBox title={tituloAtestado(tipoAtestado)} content={values.descricao} />
            </AtestadoConfirmarAssinaturaDigitalModal>
          ),
          confirmLabel: 'Enviar',
        })()
      }
    } else {
      onSubmit(values, formApi)
    }

    if (confirmed) {
      analytics.logEvent('click_gerar_atestado_digital')
      try {
        setIsAssinando(true)
        setValuesToSubmit({ values, formApi })
        await openPopup({
          tipo: tipoAtestado,
          descricao: values.descricao,
          atendimentoId: atendimentoId,
          prontuarioId: prontuarioId,
          atendimentoProfissionalIniciadoEm: dataAtendimento,
        })
      } catch {
        handleError(data?.error)
      }
    }
  }

  return {
    emailCidadao,
    handleSubmitWrapper,
    isAssinando,
  }
}
