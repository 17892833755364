import { KeyboardEvent } from 'react'

/**
 * É usado um e.preventDefault() para impedir que o form valide os campos do SOAP ao pressionar a tecla enter.
 * Para manter a usabilidade da tecla enter, é criado um evento de clique do mouse para obter o mesmo efeito.
 * Elementos html do tipo <div> não suportam a função click(), nesses casos foi disparado um evento de barra de espaço,
 * obtendo o mesmo efeito da tecla enter.
 *
 * Para manter a usabilidade da TextArea, permitindo pular linhas com o Enter,
 * quando o target é uma TextArea, mantem-se o comportamento padrao dos eventos.
 *
 */

export const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
  const target: any = event.target

  if (event.key === 'Enter' && target.tagName !== 'TEXTAREA') {
    event.preventDefault()

    if (target.tagName === 'DIV') {
      const keyboardEvent = new KeyboardEvent('keydown', { key: 'Space' })
      target.dispatchEvent(keyboardEvent)
    } else if (!isComboboxItem(target)) {
      target.click()
    }
  }
}

/**
 * O método handleKeyEnter é usado para melhorar a experiência do usuário ao interagir com SelectFields dentro de uma modal.
 * Quando o usuário pressiona a tecla 'Enter' enquanto o elemento de seleção está em foco, este método é acionado.
 *
 * A função primeiramente verifica se a tecla pressionada é 'Enter' com a ajuda da função auxiliar isEnterKey.
 * Se a tecla 'Enter' for pressionada, a função blurSelectField é chamada para remover o foco do elemento de seleção.
 *
 * Isso é especialmente útil em casos o qual o Bold UI não se comporta como esperado, como no caso do selectField de
 * 'ViaAdministracaoSelectField' dentro da modal de 'AdicionarMedicamentoFormPanel'.
 *
 */

export const handleKeyEnter = (event: KeyboardEvent<HTMLInputElement>): void => {
  if (isEnterKey(event)) {
    blurSelectField(event)
  }
}

const isEnterKey = (event: KeyboardEvent<HTMLInputElement>): boolean => event.key === 'Enter'

const blurSelectField = (event: KeyboardEvent<HTMLInputElement>): void => {
  const { currentTarget } = event
  currentTarget.blur()
}

/*
 Identifica se o elemento passado por parâmetro é um item de um SelectField, através do atributo 'role'.
 Usado para não disparar o evento de click() do método 'handleKeyDown' caso o elemento interagido seja um item de SelectField.
 */

const isComboboxItem = (target: any): boolean => {
  return target?.parentElement?.parentElement?.parentElement?.role === 'combobox'
}
