import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  AssinaturaDigitalPopupResponse,
  AssinaturaDigitalPopupResult,
  configAssinaturaDigitalByTipoDocumento,
  DocumentoDigitalErrorCodeEnum,
  EmitirAssinaturaDigitalPopupInput,
  GerarAssinaturaDigitalInputType,
  INTERVAL_DELAY_ASSINATURA_DIGITAL_POPUP,
  TipoDocumentoAssinaturaDigitalPopup,
} from './model-assinaturaDigitalPopup'

export function useAssinaturaDigitalPopup<T extends GerarAssinaturaDigitalInputType>(
  inputEmitir: EmitirAssinaturaDigitalPopupInput,
  type: TipoDocumentoAssinaturaDigitalPopup
): AssinaturaDigitalPopupResult<T> {
  const { target, gerar, emitir } = configAssinaturaDigitalByTipoDocumento[type]
  const [externalPopup, setExternalPopup] = useState<Window>(null)
  const [responseData, setResponseData] = useState<AssinaturaDigitalPopupResponse>(null)

  const openWindow = async (inputGerar: T) => {
    setResponseData(null)
    const response = await gerar(inputGerar)
    const windowAssinatura = window.open(
      response.data,
      target,
      `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}`
    )

    setExternalPopup(windowAssinatura)
  }

  useEffect(() => {
    if (!externalPopup || externalPopup.closed) return

    const timer = setInterval(async () => {
      if (!externalPopup || externalPopup.closed) {
        timer && clearInterval(timer)
        if (!responseData?.status) {
          setResponseData({ status: 'canceled', identifier: uuidv4() })
        }
        return
      }

      let currentUrl: string

      try {
        currentUrl = externalPopup.location.href
      } catch {
        return
      }

      if (!currentUrl) return

      const searchParams = new URL(currentUrl).searchParams
      // TODO (pec-assinador#194): alterar url param (prescricaoId) para nome mais generico
      const codigoDocumento = searchParams.get('prescricaoId')
      const error = searchParams.get('error')

      if (error) {
        timer && clearInterval(timer)
        setResponseData({
          status: 'failure',
          error: (error as unknown) as DocumentoDigitalErrorCodeEnum,
          identifier: uuidv4(),
        })
      } else if (codigoDocumento) {
        timer && clearInterval(timer)

        try {
          const response = await emitir({
            ...inputEmitir,
            codigoDocumento,
          })
          if (response) {
            setResponseData({
              status: 'success',
              response,
              codigoDocumento,
              identifier: uuidv4(),
            })
          }
        } catch {
          setResponseData({ status: 'failure', identifier: uuidv4() })
        }
      }

      return () => {
        timer && clearInterval(timer)
      }
    }, INTERVAL_DELAY_ASSINATURA_DIGITAL_POPUP)
  }, [emitir, externalPopup, inputEmitir, responseData])

  return {
    data: responseData,
    openPopup: openWindow,
    closePopup: () => externalPopup?.close(),
  }
}
