/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Tag, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps } from 'components/form'
import { useAlterarBuscaCidadaoPorPropriedadesMutation } from 'graphql/hooks.generated'

interface ConfiguracoesBuscaCidadaoPropriedadesBoxProps {
  habilitado: boolean
}

export function ConfiguracoesBuscaCidadaoPropriedadesBox(props: ConfiguracoesBuscaCidadaoPropriedadesBoxProps) {
  const { habilitado } = props

  const [alterarBuscaCidadaoPorPropriedades] = useAlterarBuscaCidadaoPorPropriedadesMutation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const handleSubmit = () => {
    alterarBuscaCidadaoPorPropriedades({
      variables: {
        input: !habilitado,
      },
    })
      .then((response) => {
        const acaoRealizada = response.data.alterarBuscaCidadaoPorPropriedades.habilitado
          ? 'habilitada'
          : 'desabilitada'
        alert('success', `Busca de cidadão por propriedades ${acaoRealizada} com sucesso`)
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box style={styles.box}>
        <HFlow>
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Busca de cidadão por propriedades</Heading>
            <Text>
              Melhora o tempo de busca pelo cidadão na Lista de atendimento permitindo que o profissional informe qual a
              propriedade de busca (CPF, CNS, nome ou data de nascimento).
            </Text>
            <Button kind='primary' size='small' onClick={formProps.handleSubmit}>
              {habilitado ? 'Desabilitar' : 'Habilitar'}
            </Button>
          </VFlow>
          {habilitado ? (
            <Tag type='success' icon='checkCircleFilled'>
              Habilitado
            </Tag>
          ) : (
            <Tag type='normal' icon='banFilled'>
              Desabilitado
            </Tag>
          )}
        </HFlow>
      </Box>
    )
  }

  return <Form onSubmit={handleSubmit} render={renderForm} />
}

const styles = {
  box: css`
    margin-top: 1rem;
  `,
}
