import { PagedTable, PagedTableProps } from 'bold-ui'
import { TableBox } from 'components/table'
import { createRender } from 'components/table/render'
import React from 'react'
import { formatDate } from 'util/date/formatDate'
import { AcompanhamentoCidadaosVinculadosRowModel } from 'view/acompanhamento-cidadaos-vinculados/model'

import {
  renderCidadaoAcompanhamentoCidadaosVinculados,
  renderTelefoneAcompanhamentoCidadaosVinculados,
  renderUltimaAtualizacaoCadastralEOrigem,
} from './renders'

interface AcompanhamentoCidadaosVinculadosPagedTableProps {
  pagedTableProps: Omit<PagedTableProps<AcompanhamentoCidadaosVinculadosRowModel>, 'columns'>
  rows: AcompanhamentoCidadaosVinculadosRowModel[]
}

const PADDING_DEFAULT = { padding: '0.5rem 1rem' }
const PADDING_RIGTH_ZERO = { paddingRight: '0' }

export function AcompanhamentoCidadaosVinculadosPagedTable(props: AcompanhamentoCidadaosVinculadosPagedTableProps) {
  const { rows, pagedTableProps } = props

  return (
    <TableBox>
      <PagedTable<AcompanhamentoCidadaosVinculadosRowModel>
        {...pagedTableProps}
        rows={rows}
        columns={[
          {
            name: 'cidadao',
            header: 'Cidadão',
            render: renderCidadaoAcompanhamentoCidadaosVinculados,
            style: { width: '13rem', ...PADDING_DEFAULT, ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'sexo',
            header: 'Sexo e identidade de gênero',
            render: (row) => row.sexoIdentidadeGenero,
            style: { textAlign: 'center', ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'idade',
            header: 'Idade',
            render: (row) => row.idade,
            style: { textAlign: 'center', width: '5.5rem', ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'dataNascimento',
            header: 'Data de nasc.',
            render: (row) => formatDate(row.dataNascimento),
            style: { textAlign: 'center', ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'endereco',
            header: 'Endereço',
            render: (row) => row.endereco,
            style: { width: '20rem', ...PADDING_DEFAULT, ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'telefone',
            header: 'Telefone',
            render: (row) => createRender(row, 'telefone', renderTelefoneAcompanhamentoCidadaosVinculados),
            style: { textAlign: 'center', width: '11rem', ...PADDING_DEFAULT, ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'ultimaAtualizacaoCadastral',
            header: 'Última atualização cadastral',
            render: (row) => renderUltimaAtualizacaoCadastralEOrigem(row.dataUltimaAtualizacaoCadastral, row.origem),
            style: { textAlign: 'center', ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'microarea',
            header: 'Microárea',
            render: (row) => row.microarea,
            style: { textAlign: 'center', ...PADDING_RIGTH_ZERO },
          },
          {
            name: 'equipe',
            header: 'Equipe',
            render: (row) => row.equipe,
            style: { textAlign: 'center', width: '10rem', ...PADDING_DEFAULT },
          },
        ]}
      />
    </TableBox>
  )
}
