import { Alert, Cell, FormControl, Grid, Heading, HeadingSection, HFlow, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { CheckboxField, NumberField, RadioField } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import { GrupoCondicaoSelectField } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { FormApi } from 'final-form'
import { GrupoCondicaoEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'

import { GrupoCondicaoSelectModel } from '../../components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'
import GrupoCondicaoBuscaRapida from './components/GrupoCondicaoBuscaRapida'
import SexoIdentidadeGeneroButtonGroup from './components/SexoIdentidadeGeneroButtonGroup'
import { SearchFooter } from './SearchFooter'

const path = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

const onClearField = (form: FormApi<AcompanhamentoCondicaoSaudeFormModel>, field: string) => {
  form.change(field, undefined)
  form.blur(field)
}

export default function AcompanhamentoCondicaoSaudeForm(props: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) {
  const { values, form, handleSubmit } = props
  const { acesso, hasAuthorization } = useAcessoLotacaoOrEstagio()

  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  form.change('unidadeResponsavelId', acesso?.unidadeSaude?.id)
  form.change('equipeResponsavelId', acesso?.equipe?.id)
  form.change('isCoordenador', isCoordenador)

  const handleChangeCiapCid = (ciapCidList: CiapCidSelectModel[]) => {
    if ((!ciapCidList || ciapCidList.length === 0) && (!values.grupoCondicao || values.grupoCondicao.length === 0)) {
      form.change(path.ativoListaProblema.alias, false)
    }
  }

  const handleAgeRangeChange = () => {
    form.change(path.faixaEtaria.alias, undefined)
  }

  const handleOnChangeGrupoCond = (grupos: GrupoCondicaoSelectModel[]) => {
    if (grupos) {
      if (grupos.find((item) => GrupoCondicaoEnum.DIABETES === item.grupoCondicao)) {
        !values.diabetesCheck && form.change(path.diabetesCheck.alias, !values.diabetesCheck)
      } else {
        values.diabetesCheck && form.change(path.diabetesCheck.alias, !values.diabetesCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL === item.grupoCondicao)) {
        !values.hipertensaoCheck && form.change(path.hipertensaoCheck.alias, !values.hipertensaoCheck)
      } else {
        values.hipertensaoCheck && form.change(path.hipertensaoCheck.alias, !values.hipertensaoCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.OBESIDADE === item.grupoCondicao)) {
        !values.obesidadeCheck && form.change(path.obesidadeCheck.alias, !values.obesidadeCheck)
      } else {
        values.obesidadeCheck && form.change(path.obesidadeCheck.alias, !values.obesidadeCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.GRAVIDEZ === item.grupoCondicao)) {
        !values.gravidezCheck && form.change(path.gravidezCheck.alias, !values.gravidezCheck)
      } else {
        values.gravidezCheck && form.change(path.gravidezCheck.alias, !values.gravidezCheck)
      }
    }
    if ((!grupos || grupos.length === 0) && (!values.problemasCondicoes || values.problemasCondicoes.length === 0)) {
      form.change(path.ativoListaProblema.alias, false)
    }
  }

  const canCheckApenasProblemasCondicoesAtivas =
    (!values.grupoCondicao || values.grupoCondicao.length === 0) &&
    (!values.problemasCondicoes || values.problemasCondicoes.length === 0)

  const handleClearCheckbox = useCallback(
    (numberOfGroups: number) => {
      numberOfGroups === 0 &&
        canCheckApenasProblemasCondicoesAtivas &&
        form.change(path.ativoListaProblema.alias, false)
    },
    [canCheckApenasProblemasCondicoesAtivas, form]
  )

  return (
    <VFlow vSpacing={2}>
      <Grid wrap>
        <Cell size={3} style={{ marginBottom: '1rem' }}>
          <FormControl label='Unidade responsável'>
            <Text>{acesso?.unidadeSaude?.nome.titleCase()}</Text>
          </FormControl>
        </Cell>
        <Cell size={isCoordenador ? 4 : 3} style={{ marginBottom: '1rem' }}>
          <EquipeResponsavelField
            name={path.equipeResponsavel}
            equipe={acesso?.equipe}
            unidadeSaudeId={values?.unidadeResponsavelId}
            hasAccessToTodasEquipes={isCoordenador}
            placeholder='Selecione uma equipe'
            required={isCoordenador}
            onClear={() => onClearField(form, path.equipeResponsavel.absolutePath())}
          />
        </Cell>
        <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
          <Cell size={12}>
            <Heading level={2}>Problemas e condições</Heading>
            <Alert inline type='info' style={{ marginTop: '0.5rem', marginRight: '4.5rem' }}>
              A busca retorna apenas cidadãos que possuem todas as condições selecionadas e ativas na lista de problemas
              ou avaliadas no SOAP e fichas de atendimento individual.
            </Alert>
          </Cell>
          <Cell size={12}>
            <GrupoCondicaoBuscaRapida
              path={path}
              value={values}
              onChange={form.change}
              handleClearCheckbox={handleClearCheckbox}
            />
          </Cell>
          <Cell size={6} style={{ marginTop: '0.5rem' }}>
            <GrupoCondicaoSelectField
              label='Grupos de condições prioritários'
              name={path.grupoCondicao}
              multiple
              placeholder='Selecione grupos de condições prioritários'
              onChange={handleOnChangeGrupoCond}
            />
          </Cell>
          <Cell size={6} style={{ marginTop: '0.5rem' }}>
            <CiapCidSelectField
              label='CIAP2 e CID10'
              name={path.problemasCondicoes}
              multiple
              placeholder='Selecione outros CIAP2 e CID10'
              onChange={handleChangeCiapCid}
            />
          </Cell>
          <Cell size={12}>
            <CheckboxField
              name={path.ativoListaProblema}
              label='Buscar apenas problemas / condições ativas na lista de problemas e condições.'
              disabled={canCheckApenasProblemasCondicoesAtivas}
            />
          </Cell>
        </CheckPermission>
        <Cell size={12}>
          <Heading level={2}>Informações do cidadão</Heading>
        </Cell>
        <Cell size={6}>
          <SexoIdentidadeGeneroButtonGroup onChange={form.change} meta={path} />
        </Cell>
        <Cell size={6} />
        <Cell size={4}>
          <DateRangeField
            name={path.periodoUltimoAtendimento}
            label='Período do último atendimento individual'
            startPlaceholder='Data inicial'
            finalPlaceholder='Data final'
            maxDate={new Date()}
          />
        </Cell>
        <Cell size={12} style={{ paddingBottom: 0 }}>
          <HeadingSection title='Faixa etária' level={5} />
        </Cell>
        <Cell size={4} style={{ paddingTop: 0 }}>
          <VFlow vSpacing={0.5}>
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.TODAS_FAIXAS}
              label='Todas as faixas'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.CRIANCA}
              label='Criança (0 a 10 anos)'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.ADOLESCENTE}
              label='Adolescente (11 a 19 anos)'
              onChange={handleAgeRangeChange}
            />
          </VFlow>
        </Cell>
        <Cell size={8} style={{ paddingTop: 0 }}>
          <VFlow vSpacing={0.5}>
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.ADULTO}
              label='Adulto (20 a 59 anos)'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.IDOSO}
              label='Idoso (60 anos ou mais)'
              onChange={handleAgeRangeChange}
            />
            <HFlow style={{ maxWidth: '28rem' }}>
              <div>
                <RadioField
                  name={path.faixaEtariaFilter}
                  value={AgeRangeFilter.OUTRA}
                  label='Outra'
                  onChange={handleAgeRangeChange}
                />
              </div>
              <AgeRangeField name={path.faixaEtaria} disabled={values?.faixaEtariaFilter !== AgeRangeFilter.OUTRA} />
            </HFlow>
          </VFlow>
        </Cell>
        <Cell size={12}>
          <HFlow alignItems='flex-end'>
            <NumberField
              disabled={
                values?.microarea === 'FA' ||
                values?.microarea === '--' ||
                (isCoordenador && !values?.equipeResponsavel)
              }
              style={{ maxWidth: '8rem' }}
              name={path.microarea}
              label='Microárea'
              maxLength={2}
            />
            <CheckboxField
              disabled={isCoordenador && !values?.equipeResponsavel}
              name='foraarea'
              label='Fora de área'
              checked={values?.microarea === 'FA'}
              onChange={(e) => form.change(path.microarea.alias, e.target.checked ? 'FA' : undefined)}
            />
            <CheckboxField
              disabled={isCoordenador && !values?.equipeResponsavel}
              name='naoinformada'
              label='Não informada'
              checked={values?.microarea === '--'}
              onChange={(e) => form.change(path.microarea.alias, e.target.checked ? '--' : undefined)}
            />
          </HFlow>
        </Cell>
      </Grid>
      <SearchFooter
        handleSubmit={handleSubmit}
        onCleanFilters={form.reset}
        isCoordenador={isCoordenador}
        equipeResponsavelId={values.equipeResponsavelId}
      />
    </VFlow>
  )
}
