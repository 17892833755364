import { HFlow } from 'bold-ui'
import { CheckBoxButtonField } from 'components/form/field/CheckBoxButtonField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { GrupoCondicaoEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'

export default function GrupoCondicaoBuscaRapida(props) {
  const { path, value, onChange, handleClearCheckbox } = props
  const [numberOfGroups, setNumberOfGroups] = useState<number>(0)

  useEffect(() => {
    handleClearCheckbox(numberOfGroups)
  }, [handleClearCheckbox, numberOfGroups])

  const pushInGrupo = (grupoEnum: GrupoCondicaoEnum) => {
    const grupo = { grupoCondicao: grupoEnum } as Partial<GrupoCondicaoSelectModel>
    const list = value?.grupoCondicao ? [...value?.grupoCondicao, grupo] : [grupo]
    setNumberOfGroups(list.length)
    return list
  }

  const popFromGrupo = (grupoEnum: GrupoCondicaoEnum) => {
    const list = value?.grupoCondicao.filter(
      (item: { grupoCondicao: GrupoCondicaoEnum }) => grupoEnum !== item.grupoCondicao
    )
    setNumberOfGroups(list.length)
    return list
  }

  const handleChangeDiabetes = () => {
    !value.diabetesCheck
      ? onChange(path.grupoCondicao.alias, pushInGrupo(GrupoCondicaoEnum.DIABETES))
      : onChange(path.grupoCondicao.alias, popFromGrupo(GrupoCondicaoEnum.DIABETES))
  }

  const handleChangeHipertensao = () => {
    !value.hipertensaoCheck
      ? onChange(path.grupoCondicao.alias, pushInGrupo(GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL))
      : onChange(path.grupoCondicao.alias, popFromGrupo(GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL))
  }

  const handleChangeObesidade = () => {
    !value.obesidadeCheck
      ? onChange(path.grupoCondicao.alias, pushInGrupo(GrupoCondicaoEnum.OBESIDADE))
      : onChange(path.grupoCondicao.alias, popFromGrupo(GrupoCondicaoEnum.OBESIDADE))
  }

  const handleChangeGravidez = () => {
    !value.gravidezCheck
      ? onChange(path.grupoCondicao.alias, pushInGrupo(GrupoCondicaoEnum.GRAVIDEZ))
      : onChange(path.grupoCondicao.alias, popFromGrupo(GrupoCondicaoEnum.GRAVIDEZ))
  }

  return (
    <HFlow hSpacing={0.5}>
      <CheckBoxButtonField name={path.diabetesCheck.alias} label='Diabetes' onChange={handleChangeDiabetes} />
      <CheckBoxButtonField
        name={path.hipertensaoCheck.alias}
        label='Hipertensão arterial'
        onChange={handleChangeHipertensao}
      />
      <CheckBoxButtonField name={path.obesidadeCheck.alias} label='Obesidade' onChange={handleChangeObesidade} />
      <CheckBoxButtonField name={path.gravidezCheck.alias} label='Gravidez' onChange={handleChangeGravidez} />
    </HFlow>
  )
}
