import { FormaFarmaceuticaSelectFieldModel } from 'components/form/field/select/FormaFarmaceuticaSelectField'
import { MedicamentoCatmatSelectModel } from 'components/form/field/select/MedicamentoCatMatSelectField'
import { TipoReceitaSelectModel } from 'components/form/field/select/TipoReceitaSelectField'
import { UnidadeMedidaSelectModel } from 'components/form/field/select/UnidadeMedidaSelectField'
import { ViaAdministracaoSelectModel } from 'components/form/field/select/ViaAdministracaoSelectField/ViaAdministracaoSelectField'
import {
  AplicacaoMedicamento,
  AtendimentoProfissional,
  DisponibilidadeHorusEnum,
  Medicamento,
  MedicamentoCatmat,
  PrincipiosAtivosAlergiasQuery,
  TipoFrequencia,
  TipoReceitaEnum,
  TurnoEnum,
  UnidadeMedida,
  UnidadeMedidaTempoEnum,
} from 'graphql/types.generated'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'

interface MedicamentoCatmatQueryModel extends Omit<MedicamentoCatmat, 'medicamento'> {}

interface MedicamentoQueryModel extends Omit<Medicamento, 'medicamentoCatmat'> {
  medicamentoCatmat?: MedicamentoCatmatQueryModel
}

export enum TipoDoseEnum {
  COMUM = 'COMUM',
  UNICA = 'ÚNICA',
  FRACIONADA = 'FRACIONADA',
}

export interface ReceitaMedicamentoQueryModel {
  id: ID
  medicamento: MedicamentoQueryModel
  aplicacaoMedicamento: AplicacaoMedicamento
  quantidadeReceitada: number
  recomendacoes?: string
  tipoDose?: TipoDoseEnum
  dose?: string
  qtDose?: string
  qtDoseManha?: string
  qtDoseTarde?: string
  qtDoseNoite?: string
  unidadeMedidaDose?: UnidadeMedida
  frequenciaDose?: string
  posologia: string
  tipoFrequenciaDose?: TipoFrequencia
  qtPeriodoFrequencia?: number
  dtInicioTratamento?: Instant
  dtFimTratamento?: Instant
  qtDuracaoTratamento?: number
  medidaTempoTratamento?: UnidadeMedidaTempoEnum
  medidaTempoFrequencia?: UnidadeMedidaTempoEnum
  tipoReceita?: TipoReceitaEnum
  justificativaInterrupcao?: string
  dtInterrupcao?: Instant
  usoContinuo?: boolean
  registroManual?: boolean
  doseUnica?: boolean
  tratamentoInterrompido?: boolean
  atendProf?: AtendimentoProfissional
}

export interface MedicamentoFormModel extends SoapEditableItem {
  id?: ID

  // registro de medicamento não cadastrado/listado
  registroManual?: boolean

  // principio ativo
  principioAtivoCombo?: MedicamentoCatmatSelectModel

  // principio ativo (não cadastrado)
  principioAtivoText?: string

  // concentração (não cadastrado)
  concentracaoText?: string

  // via de administração
  viaAdministracao?: ViaAdministracaoSelectModel

  // unidade de medida
  unidadeMedidaDose?: UnidadeMedidaSelectModel

  // tipo de receita (não cadastrado)
  tipoReceita?: TipoReceitaSelectModel

  codigoPrescricaoDigital?: string

  // forma farmaceutica (não cadastrado)
  formaFarmaceutica?: FormaFarmaceuticaSelectFieldModel

  // dose (campo legado)
  dose?: string

  // quantidade da dose
  qtDose?: string
  doseUnica?: boolean

  qtDoseManha?: string
  qtDoseTarde?: string
  qtDoseNoite?: string

  tipoDose?: TipoDoseEnum

  // frequencia
  tipoFrequencia?: TipoFrequencia

  intervaloDose?: number

  frequenciaDose?: number

  quantidadePeriodoFrequenciaTurno?: number
  unidadeMedidaTempoFrequenciaTurno?: UnidadeMedidaTempoEnum

  // turno
  turno?: TurnoEnum

  //posologia
  posologia?: string

  // data inicio e fim do tratamento
  dataInicioTratamento?: LocalDate
  dataFimTratamento?: LocalDate

  //duracao
  duracao?: number
  escalaDuracao?: UnidadeMedidaTempoEnum

  //quantidade
  quantidade?: number

  // uso continuo
  usoContinuo?: boolean

  // recomendacoes
  recomendacoes?: string

  // unidade de fornecimento
  unidadeFornecimento?: string

  // disponibilidade do medicamento no Hórus
  disponibilidade?: DisponibilidadeHorusEnum

  // variável de auxilio para exibir erro em forma de box
  frequenciaDuracao?: any

  isVersaoAnterior?: boolean

  isEditFirstLoad?: boolean

  totalDoseError?: boolean
  turnosError?: boolean
}

export interface PosologiaLabelParams {
  tipoFrequencia: TipoFrequencia
  intervaloDose: number
  frequenciaDose: number
  quantidadePeriodoFrequencia: number
  unidadeMedidaTempoFrequencia: UnidadeMedidaTempoEnum
  turno: TurnoEnum
  doseUnica: boolean
  qtDose?: string
  unidadeMedida?: UnidadeMedidaSelectModel
  qtDoseManha?: string
  qtDoseTarde?: string
  qtDoseNoite?: string
  tipoDose?: TipoDoseEnum
  doseLegado?: string
  PRESCRICAO_TURNO?: Boolean
}

export type PrincipiosAtivosAlergiasQueryPayload = PrincipiosAtivosAlergiasQuery['principiosAtivosAlergias']
