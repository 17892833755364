import { Heading, HFlow } from 'bold-ui'
import { useStatusConexaoRndsQuery } from 'graphql/hooks.generated'
import React from 'react'

import StatusConexaoRndsTag from './StatusConexaoRndsTag'

export function StatusUltimoEnvioRnds() {
  const {
    data: { statusConexaoRnds },
  } = useStatusConexaoRndsQuery()

  return (
    <HFlow hSpacing={0.5}>
      <Heading level={4}>Status do último envio</Heading>
      {statusConexaoRnds && <StatusConexaoRndsTag statusConexaoRnds={statusConexaoRnds} />}
    </HFlow>
  )
}
