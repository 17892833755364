import { Button, ButtonGroup, Cell, Grid, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField } from 'components/form'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { MetaRoot } from 'util/metaPath'

import AcompanhamentoCondicaoSaudeFormModel from '../AcompanhamentoCondicaoSaudeFormModel'

interface SexoIdentidadeGeneroButtonGroup {
  onChange?: (name: string, value?: any) => void
  meta: MetaRoot<AcompanhamentoCondicaoSaudeFormModel>
}

export default function SexoIdentidadeGeneroButtonGroup(props: SexoIdentidadeGeneroButtonGroup) {
  const { onChange, meta } = props

  const [option, setOption] = useState<boolean>(false)

  const handleChangeButtonGroup = (itemGroup: boolean) => {
    return () => {
      setOption(itemGroup)
      itemGroup
        ? onChange(meta.sexo.absolutePath(), undefined)
        : onChange(meta.identidadeGenero.absolutePath(), undefined)
    }
  }

  return (
    <VFlow vSpacing={0.5}>
      <ButtonGroup>
        <Button
          kind={!option ? 'primary' : 'normal'}
          size='small'
          skin='default'
          onClick={handleChangeButtonGroup(false)}
        >
          Sexo
        </Button>
        <Button
          kind={option ? 'primary' : 'normal'}
          size='small'
          skin='default'
          onClick={handleChangeButtonGroup(true)}
        >
          Identidade de gênero
        </Button>
      </ButtonGroup>
      <Box>
        {!option ? (
          <>
            <CheckboxField
              name={meta.sexo}
              value={SexoEnum.FEMININO}
              label='Feminino'
              style={{ marginRight: '1rem' }}
            />
            <CheckboxField name={meta.sexo} value={SexoEnum.MASCULINO} label='Masculino' />
          </>
        ) : (
          <Grid gap={1}>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Mulher cisgênero'
                value={IdentidadeGeneroEnum.MULHER_CIS}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Homem cisgênero'
                value={IdentidadeGeneroEnum.HOMEM_CIS}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Não-Binário'
                value={IdentidadeGeneroEnum.NAO_BINARIO}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Mulher transgênero'
                value={IdentidadeGeneroEnum.MULHER_TRANS}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Homem transgênero'
                value={IdentidadeGeneroEnum.HOMEM_TRANS}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Transgênero'
                value={IdentidadeGeneroEnum.TRANSGENERO}
              />
            </Cell>
            <Cell size={4}>
              <CheckboxField name={meta.identidadeGenero} label='Travesti' value={IdentidadeGeneroEnum.TRAVESTI} />
            </Cell>
            <Cell size={4}>
              <CheckboxField
                name={meta.identidadeGenero}
                label='Outro'
                value={IdentidadeGeneroEnum.OUTRO_IDENTIDADE_GENERO}
              />
            </Cell>
            <Cell size={4} />
          </Grid>
        )}
      </Box>
    </VFlow>
  )
}
