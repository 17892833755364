import { BuscaAtivaVacinacaoInput } from 'graphql/types.generated'
import { isNumber } from 'lodash'

import { BuscaAtivaVacinacaoFormModel } from './model-buscaAtiva'

export default (model: BuscaAtivaVacinacaoFormModel): BuscaAtivaVacinacaoInput => ({
  grupoAlvo: model.grupoAlvo?.enum,
  faixaEtaria: {
    idadeMinima: model.faixaEtaria?.firstValue,
    idadeMaxima: model.faixaEtaria?.secondValue,
    tipoFaixaEtaria:
      isNumber(model.faixaEtaria?.firstValue) || isNumber(model.faixaEtaria?.secondValue)
        ? model.faixaEtaria?.periodo
        : null,
  },
  status: model.status?.enum,
  equipeVinculadaIne: model.equipe?.ine,
  microareas: model.microareas,
  filtrosAvancados: {
    imunobiologicoIds: model.filtrosAvancados?.imunobiologicos?.map((imuno) => imuno.id),
    doseIds: model.filtrosAvancados?.doses?.map((dose) => dose.id),
    grupoAtendimentoIds: model.filtrosAvancados?.gruposAtendimento?.map((grupo) => grupo.id),
    bairro: model.filtrosAvancados?.bairro,
  },
  tipoVisualizacao: model.tipoVisualizacao,
  pageParams: {
    sort: ['cidadao', 'idade'],
    ...model.pageParams,
  },
})
