import { catmatAcitretina, catmatIsotretinoina, catmatTretinoina } from 'api/ImpressaoMedicamentos'
import { UnidadeMedidaSelectModel } from 'components/form/field/select/UnidadeMedidaSelectField'
import { addDays, addHours, addMonths, addWeeks, isAfter, parseISO, subDays, subHours } from 'date-fns'
import { DisponibilidadeHorusEnum, TipoFrequencia, TurnoEnum, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { msg, parseNumber } from 'util/validation'

import { PrescricaoDigitalFormModel } from '../modal/prescricao-digital/model-prescricaoDigital'
import { MedicamentoFormModel, PosologiaLabelParams, TipoDoseEnum } from '../model-prescricao'

const enumToStringTurno: Record<TurnoEnum, string> = {
  [TurnoEnum.MANHA]: 'manhã',
  [TurnoEnum.TARDE]: 'tarde',
  [TurnoEnum.NOITE]: 'noite',
}

export const enumToStringDisponibilidadeHorus: Record<DisponibilidadeHorusEnum, string> = {
  [DisponibilidadeHorusEnum.DISPONIVEL]: 'Medicamento disponível',
  [DisponibilidadeHorusEnum.DESABILITADO_MANUAL]: 'Prescrições manuais não consultam o servidor Hórus',
  [DisponibilidadeHorusEnum.DESABILITADO]: 'Conexão com o servidor Hórus desabilitada',
  [DisponibilidadeHorusEnum.HABILITADO]: 'Conexão com o servidor Hórus desabilitada',
  [DisponibilidadeHorusEnum.INDISPONIVEL]: 'Medicamento indisponível',
  [DisponibilidadeHorusEnum.PARCIALMENTE_DISPONIVEL]: 'Medicamento parcialmente disponível',
  [DisponibilidadeHorusEnum.SEM_CONEXAO]: 'Falha na conexão com o servidor Hórus',
  [DisponibilidadeHorusEnum.SUCESSO_CONEXAO]: 'A conexão com o Hórus foi realizada com sucesso',
}

export const calculateDataFimTratamento = (amount: number, date: LocalDate, scale: UnidadeMedidaTempoEnum) => {
  if (!amount || !date) {
    return null
  }

  if (scale) {
    switch (scale) {
      case UnidadeMedidaTempoEnum.DIAS:
        return addDays(parseISO(date), amount - 1)
      case UnidadeMedidaTempoEnum.SEMANAS:
        return subDays(addWeeks(parseISO(date), amount), 1)
      case UnidadeMedidaTempoEnum.MESES:
        return subDays(addMonths(parseISO(date), amount), 1)
      default:
        return null
    }
  } else {
    return subHours(addHours(parseISO(date), amount), 24)
  }
}

export const createPosologiaLabel = (params: PosologiaLabelParams) => {
  const {
    tipoFrequencia,
    frequenciaDose,
    intervaloDose,
    qtDose,
    quantidadePeriodoFrequencia,
    unidadeMedidaTempoFrequencia,
    turno,
    tipoDose,
    doseUnica,
    unidadeMedida,
    doseLegado,
    qtDoseManha,
    qtDoseTarde,
    qtDoseNoite,
    PRESCRICAO_TURNO,
  } = params

  if (
    ((tipoFrequencia === TipoFrequencia.FREQUENCIA && !frequenciaDose) ||
      (tipoFrequencia === TipoFrequencia.INTERVALO && !intervaloDose)) &&
    !qtDose
  ) {
    return 'Nenhuma informação adicionada'
  }
  return createFrequenciaReceitaLabel(
    tipoFrequencia,
    intervaloDose,
    frequenciaDose,
    quantidadePeriodoFrequencia,
    unidadeMedidaTempoFrequencia,
    turno,
    (!PRESCRICAO_TURNO && doseUnica) || (PRESCRICAO_TURNO && tipoDose === TipoDoseEnum.UNICA),
    createDoseReceitaLabel(unidadeMedida, qtDose, doseLegado),
    createDoseReceitaLabel(unidadeMedida, qtDoseManha),
    createDoseReceitaLabel(unidadeMedida, qtDoseTarde),
    createDoseReceitaLabel(unidadeMedida, qtDoseNoite)
  )
}

export function createFrequenciaReceitaLabel(
  tipoFrequencia: TipoFrequencia,
  intervaloDose: number,
  frequenciaDose: number,
  quantidadePeriodoFrequencia: number,
  unidadeMedidaTempoFrequencia: UnidadeMedidaTempoEnum,
  turno: TurnoEnum,
  doseUnica: boolean,
  doseText?: string,
  doseManhaText?: string,
  doseTardeText?: string,
  doseNoiteText?: string
) {
  const appendCommaToDoseText = (frequenciaInfo: any) => `${doseText ?? ''}${doseText && frequenciaInfo ? ',' : ''}`

  if (doseUnica) {
    const doseUnicaText = 'dose única'
    return `${appendCommaToDoseText(doseUnicaText)} ${doseUnicaText}`
  } else {
    const frequenciaText: string =
      quantidadePeriodoFrequencia && unidadeMedidaTempoFrequencia
        ? `${unidadeTempoConverter(
            parseNumber(quantidadePeriodoFrequencia),
            unidadeMedidaTempoFrequencia,
            tipoFrequencia
          )}`
        : ''
    const turnoText: string = turno ? `pela ${enumToStringTurno[turno]}` : ''

    switch (tipoFrequencia) {
      case TipoFrequencia.INTERVALO:
        return `${appendCommaToDoseText(intervaloDose)} ${
          intervaloDose ? `a cada ${hourToDay(parseNumber(intervaloDose))}` : ''
        }`
      case TipoFrequencia.FREQUENCIA:
        return `${appendCommaToDoseText(frequenciaDose)} ${
          frequenciaDose
            ? parseNumber(frequenciaDose) === 1
              ? `${frequenciaDose} vez`
              : `${frequenciaDose} vezes`
            : ''
        } ${frequenciaText}`
      case TipoFrequencia.TURNO:
        return `${appendCommaToDoseText(turno)} ${turnoText} ${frequenciaText ?? ''}`
      case TipoFrequencia.FRACIONADO:
        const doseFracionadaText =
          doseText && (doseManhaText || doseTardeText || doseNoiteText)
            ? (
                `${doseManhaText ? doseManhaText : ''}${doseManhaText ? ' pela manhã' : ''}` +
                `${doseTardeText ? ', ' : ''}` +
                `${doseTardeText ? doseTardeText : ''}${doseTardeText ? ' pela tarde' : ''}` +
                `${(doseManhaText || doseTardeText) && doseNoiteText ? ', ' : ''}` +
                `${doseNoiteText ? doseNoiteText : ''}${doseNoiteText ? ' pela noite' : ''}`
              ).replaceLastOccurrence(', ', ' e')
            : ''

        return `${doseText ?? ''} ${frequenciaText ?? ''}${doseText ? ', sendo: ' : ''}${doseFracionadaText}`
      default:
        return null
    }
  }
}

export function createDoseReceitaLabel(
  unidadeMedida?: UnidadeMedidaSelectModel,
  qtDose?: string,
  doseLegado?: string
): string {
  if (doseLegado) return doseLegado

  if (!unidadeMedida || !qtDose) return

  const parsedDose = parseNumber(qtDose)
  const qtDoseText: string = qtDose?.replace('.', ',')
  const unidadeMedidaText: string = (parsedDose >= 2 ? unidadeMedida.nomePlural : unidadeMedida.nome).toLowerCase()
  return qtDose && unidadeMedida ? `${qtDoseText} ${unidadeMedidaText}` : ''
}

export function unidadeTempoConverter(value: number, unidade: UnidadeMedidaTempoEnum, tipoFrequencia: TipoFrequencia) {
  switch (unidade) {
    case UnidadeMedidaTempoEnum.DIAS:
      if (value === 1) {
        if (tipoFrequencia === TipoFrequencia.TURNO) {
          return ''
        }
        if (tipoFrequencia === TipoFrequencia.FRACIONADO) {
          return 'a cada 1 dia'
        }
        return 'ao dia'
      }
      return `a cada ${value} dias`
    case UnidadeMedidaTempoEnum.SEMANAS:
      return `a cada ${value} ${value === 1 ? 'semana' : 'semanas'}`
    case UnidadeMedidaTempoEnum.MESES:
      return `a cada ${value} ${value === 1 ? 'mês' : 'meses'}`
    default:
      return undefined
  }
}

export function hourToDay(value: number) {
  if (value % 24 === 0) {
    const result: number = value / 24
    return `${result} ${result > 1 ? 'dias' : 'dia'}`
  } else {
    return `${value > 1 ? `${value} horas` : 'hora'}`
  }
}

export function isCampoInvalido(condition: boolean) {
  return (value: string | number) => (condition ? msg('invalid', value) : null)
}

export function duracaoFrequencia(
  frequencia: number,
  dataInicioTratamento: LocalDate,
  escalaFrequenciaDose: UnidadeMedidaTempoEnum,
  escalaDuracao: UnidadeMedidaTempoEnum
) {
  return (value: number) => {
    const dataFinalFrequencia = calculateDataFimTratamento(frequencia, dataInicioTratamento, escalaFrequenciaDose)
    const dataFinalDuracao = calculateDataFimTratamento(parseNumber(value), dataInicioTratamento, escalaDuracao)

    return isAfter(dataFinalFrequencia, dataFinalDuracao)
      ? 'Duração do tratamento deve ser maior ou igual à frequência da dose'
      : null
  }
}

export const frequenciaDaDoseUnica: string = 'Medicamento definido como dose única'

export const calculaDisponibilidadeHorus = (
  quantidadeDisponivel: number,
  quantidadeDesejada: number
): DisponibilidadeHorusEnum => {
  if (quantidadeDisponivel === 0) return DisponibilidadeHorusEnum.INDISPONIVEL
  else if (quantidadeDesejada > quantidadeDisponivel) return DisponibilidadeHorusEnum.PARCIALMENTE_DISPONIVEL
  else return DisponibilidadeHorusEnum.DISPONIVEL
}

export const hasIsotretinoinaTretinoinaAcitretina = (medicamentosToPrint: MedicamentoFormModel[]) =>
  medicamentosToPrint.some((med) => {
    return (
      catmatAcitretina.some((element) => {
        return element === med.principioAtivoCombo?.codigoCatMat
      }) ||
      catmatIsotretinoina.some((element) => {
        return element === med.principioAtivoCombo?.codigoCatMat
      }) ||
      catmatTretinoina.some((element) => {
        return element === med.principioAtivoCombo?.codigoCatMat
      })
    )
  })

export function unidadeMedidaToText(qtDose: string, unidadeMedida: UnidadeMedidaSelectModel) {
  return unidadeMedida
    ? parseNumber(qtDose) >= 2
      ? unidadeMedida?.nomePlural.toLowerCase()
      : unidadeMedida?.nome.toLowerCase()
    : ''
}

export const isOnPrescricaoDigitalAtiva = (
  prescricaoDigital: PrescricaoDigitalFormModel,
  medicamento: MedicamentoFormModel
) =>
  !!medicamento.codigoPrescricaoDigital &&
  !prescricaoDigital?.[medicamento.codigoPrescricaoDigital]?.prescricaoInvalidada

export const hasPrescricaoDigitalAtiva = (prescricaoDigital: PrescricaoDigitalFormModel): boolean =>
  Object.values(prescricaoDigital).some((el) => !el.prescricaoInvalidada)
