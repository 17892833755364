/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Checkbox, DropdownItem, Grid, HFlow, Icon, TableFooter, Theme, Tooltip, useTheme } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import CheckPermission from 'components/auth/CheckPermission'
import { DropdownButton } from 'components/dropdown'
import { useLembretesModalQuery } from 'graphql/hooks.generated'
import { useFilter } from 'hooks/filter/useFilter'
import { isEqual } from 'lodash'
import { MouseEvent, useMemo, useState } from 'react'
import { MetaPath } from 'util/metaPath'
import { LembreteFilterModel, LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { grupoCboLembretes } from 'view/atendimentos/detail/soap/plano/acessos'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { useAccordionControl } from '../../../../../../components/accordion/useAccordionControl'
import { usePagination } from '../../../../../../components/table/usePagination'
import { useEditableListField } from '../../EditableList'
import { LembreteFormModel } from './components/LembreteForm'
import { convertLembreteModel } from './convert'
import LembretesAccordionPanel from './LembretesAccordionPanel'
import { LembreteTableRow } from './LembreteTableRow'
import { filterMostrarInativos } from './ListaLembretesFilter'
import { mergeSortLembretes } from './mergeSortLembretes'
import { renderDescricaoLembrete, renderInativoLembrete, renderVisibilidadeLembrete } from './render'

interface EditableListaLembretesModalProps {
  prontuarioId: ID
  name: MetaPath<LembreteFormModel>
  lotacao: LotacaoAtendimento
  isAtendimentoObservacao: boolean
  lembretesCache?: LembreteFormModel[]
}

export function EditableListaLembretesModal(props: EditableListaLembretesModalProps) {
  const { prontuarioId, name, lotacao, lembretesCache } = props

  const [lembretesEmEdicao, setLembretesEmEdicao] = useState<Array<LembreteModel>>([])

  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})

  const isEditing = (lembrete: LembreteModel): boolean =>
    !!lembretesEmEdicao.find((item) => item.id === lembrete.id && item._id === lembrete._id)

  const handleEditClick = (event: MouseEvent<HTMLElement>, lembrete: LembreteModel) => {
    event.stopPropagation()
    if (!isEditing(lembrete)) setLembretesEmEdicao([...lembretesEmEdicao, lembrete])
  }

  const removeLembreteEmEdicao = (lembrete: LembreteModel) =>
    setLembretesEmEdicao(lembretesEmEdicao.filter((item) => !isEqual(item, lembrete)))

  const { handleSubmit, handleRowChanged, removeItem, handleSubmitWithoutReset } = useEditableListField({ name })

  const theme = useTheme()
  const classes = styles(theme)

  const [somenteMeus, setSomenteMeus] = useState<boolean>(false)

  const { data, loading } = useLembretesModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lembretesQueryInput: {
        prontuarioId,
        somenteMeus,
      },
    },
  })

  const lembretesCacheDatabase = useMemo(
    () => mergeSortLembretes(data?.lembretes ?? [], lembretesCache ?? [], lotacao),
    [data, lembretesCache, lotacao]
  )

  const [filter, setFilter] = useState<LembreteFilterModel>({ mostrarInativos: false })

  const lembretesFiltered = useFilter<LembreteModel, LembreteFilterModel>({
    items: lembretesCacheDatabase,
    filter: filter,
    filtersType: [],
    customFilters: [filterMostrarInativos(filter.mostrarInativos)],
  })

  const { paginatedItems, tableProps } = usePagination<LembreteModel>({
    items: lembretesFiltered,
    onChange: resetExpandedItems,
  })

  const handleDelete = (event: MouseEvent<HTMLElement>, lembreteId: ID) => {
    event.stopPropagation()
    const lembrete: LembreteFormModel = {
      _id: lembreteId,
      visibilidadeLembrete: null,
      descricao: null,
      ativo: null,
    }
    lembreteId && removeItem(lembrete)
    resetExpandedItems()
  }

  const handleAtivarOrInativar = (event: MouseEvent<HTMLElement>, lembrete: LembreteModel) => {
    event.stopPropagation()
    const lembreteChanged = convertLembreteModel(lembrete)
    lembreteChanged.ativo = !lembreteChanged.ativo
    lembrete._id ? handleRowChanged(lembreteChanged) : handleSubmitWithoutReset(lembreteChanged)
    resetExpandedItems()
  }

  const renderButtons = (row: LembreteModel) => {
    const isAtivo = row.historicoLembrete[0]?.ativo
    return (
      <CheckPermission permission={grupoCboLembretes.adicionar}>
        <Cell size={4}>
          <HFlow hSpacing={0} alignItems='center'>
            <Tooltip text='Editar'>
              <Button size='small' kind='normal' skin='ghost' onClick={(e) => handleEditClick(e, row)}>
                <Icon name='accordionEditIcon' icon='penOutline' />
              </Button>
            </Tooltip>
            <Tooltip text='Mais opções'>
              <DropdownButton stopPropagationOnClick isInsideModal disabled={isEditing(row)}>
                <DropdownItem onClick={(e) => handleAtivarOrInativar(e, row)}>
                  {isAtivo ? 'Inativar' : 'Ativar'}
                </DropdownItem>
                <DropdownItem type='danger' onClick={(e) => handleDelete(e, row._id)} disabled={!row._id}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              </DropdownButton>
            </Tooltip>
          </HFlow>
        </Cell>
      </CheckPermission>
    )
  }

  return (
    <Grid style={classes.grid}>
      <Cell size={12}>
        <HFlow style={classes.checkboxContainer}>
          <Checkbox
            label='Ver somente os lembretes criados por mim'
            onChange={() => {
              setSomenteMeus(!somenteMeus)
            }}
          />
          <Checkbox
            label='Mostrar lembretes inativos'
            onChange={() => {
              setFilter({ mostrarInativos: !filter.mostrarInativos })
            }}
          />
        </HFlow>
      </Cell>
      <Cell size={12}>
        <AccordionDataTable<LembreteModel>
          {...accordionProps}
          columns={[
            {
              name: 'descricao',
              header: 'Descrição',
              render: renderDescricaoLembrete,
              size: 12,
            },
            {
              name: 'inativo',
              header: '',
              render: renderInativoLembrete,
              size: 3,
            },
            {
              name: 'recente',
              header: '',
              render: (item) =>
                item._id && (
                  <Tooltip text='Registrado agora'>
                    <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                  </Tooltip>
                ),
              size: 1,
            },
            {
              name: 'visibilidade',
              header: 'Visibilidade',
              render: renderVisibilidadeLembrete,
              size: 5,
            },
            {
              name: 'buttons',
              header: '',
              render: renderButtons,
              size: 3,
            },
          ]}
          rows={paginatedItems}
          components={{
            AccordionPanel: LembretesAccordionPanel,
            Row: (props) => (
              <LembreteTableRow
                isEditing={isEditing(props.row)}
                onRowChanged={handleRowChanged}
                onAddRow={handleSubmit}
                removeLembreteEmEdicao={removeLembreteEmEdicao}
                {...props}
              />
            ),
          }}
          disableRow={isEditing}
          loading={loading}
        />
        <TableFooter {...tableProps} style={classes.footer} />
      </Cell>
    </Grid>
  )
}

const styles = (theme: Theme) => ({
  footer: css`
    border-top: 0rem;
    margin-top: -0.1rem;
    padding-top: 0.1rem;
  `,
  checkboxContainer: css`
    border: 1px solid ${theme.pallete.divider};
    margin-bottom: -1.05rem;
    padding: 0.6rem 0.9rem;
  `,
  grid: css`
    margin-top: 0.5rem;
  `,
})
