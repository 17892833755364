import { AgeRangeValue } from 'components/date/AgeRange'
import { EquipeSelectModel, LocalDateRange } from 'components/form'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'

export enum AgeRangeFilter {
  TODAS_FAIXAS = 'TODAS_FAIXAS',
  CRIANCA = 'CRIANÇA',
  ADOLESCENTE = 'ADOLESCENTE',
  ADULTO = 'ADULTO',
  IDOSO = 'IDOSO',
  OUTRA = 'OUTRA',
}

export default interface AcompanhamentoCondicaoSaudeFormModel {
  problemasCondicoes?: CiapCidSelectModel[]
  grupoCondicao?: GrupoCondicaoSelectModel[]
  ativoListaProblema?: boolean
  unidadeResponsavelId?: ID
  equipeResponsavelId?: ID
  equipeResponsavel?: EquipeSelectModel
  sexo?: SexoEnum[]
  identidadeGenero?: IdentidadeGeneroEnum[]
  faixaEtaria?: AgeRangeValue
  faixaEtariaFilter?: AgeRangeFilter
  faixaEtariaInicio?: LocalDate
  faixaEtariaFim?: LocalDate
  isCoordenador?: boolean
  // controle dos grupos rápidos
  diabetesCheck?: boolean
  hipertensaoCheck?: boolean
  obesidadeCheck?: boolean
  gravidezCheck?: boolean
  periodoUltimoAtendimento?: LocalDateRange
  microarea?: string
  foraarea?: boolean
  naoinformada?: boolean
}
