import { Button, useTheme, VFlow } from 'bold-ui'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import React from 'react'
import { useHistory } from 'react-router'
import { createValidator, required } from 'util/validation'

export interface SolicitarRecuperarSenhaForm {
  usuario: string
}

interface SolicitarRecupeararSenhaFormProps {
  onRequestRedefinicaoSenha(values: SolicitarRecuperarSenhaForm): any
}

const validator = createValidator<SolicitarRecuperarSenhaForm>({
  usuario: [required],
})

export const SolicitarRecuperarSenhaForm = (props: SolicitarRecupeararSenhaFormProps) => {
  const theme = useTheme()
  const history = useHistory()
  const { onRequestRedefinicaoSenha } = props

  const handleSubmitSuccess = () => history.push('/?emailEnviado')
  const handleCancelar = () => history.push('/')

  const renderForm = (formRenderProps: FormRenderProps) => {
    return (
      <form onSubmit={formRenderProps.handleSubmit} noValidate>
        <VFlow>
          <TextField name='usuario' label='Usuário' required />
          <SubmitButton
            style={{
              color: theme.pallete.gray.c100,
              border: 'none',
            }}
            block
            handleSubmit={formRenderProps.handleSubmit}
          >
            Recuperar Senha
          </SubmitButton>

          <Button onClick={handleCancelar} skin='outline' block>
            Cancelar
          </Button>
        </VFlow>
      </form>
    )
  }

  return (
    <Form
      render={renderForm}
      onSubmit={onRequestRedefinicaoSenha}
      onSubmitSucceeded={handleSubmitSuccess}
      validate={validator}
    />
  )
}
