import { createValidator, ErrorObject, required } from 'util/validation'

import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>(
  {
    equipeResponsavel: required,
  },
  (values: AcompanhamentoCondicaoSaudeFormModel, errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel>) => {
    if (AgeRangeFilter.OUTRA === values?.faixaEtariaFilter) {
      errors.faixaEtaria = required(values.faixaEtaria)
    }

    return errors
  }
)
