import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeader } from 'components/PageHeader'
import { useCriarEstagioMutation, useProfissionalEstagioQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { convertEstagioModelToInput } from '../converter'
import { EstagioFormModel } from '../model'
import { EstagioForm } from './EstagioForm'
interface UrlParams {
  profissionalId: string
}

export function EstagioCreateView() {
  const match = useRouteMatch<UrlParams>()
  const [salvar] = useCriarEstagioMutation()
  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const history = useHistory()
  const session = useSession()
  const { analytics } = useFirebase()

  const {
    data: { profissional },
    loading,
  } = useProfissionalEstagioQuery({
    variables: { id: match.params.profissionalId },
  })

  if (loading) return <LoadingIndicator />

  const handleSubmit = (values: EstagioFormModel) => {
    const input = convertEstagioModelToInput({ ...values, profissionalId: profissional.id })
    salvar({ variables: { input } })
      .then(profissional.id === session.data.profissional.id && session.refresh)
      .then(handleSubmitSuccess)
      .catch(handleRejection)
  }

  const handleSubmitSuccess = () => {
    analytics.logEvent('cadastro_lotacao_estagio_EST')
    alert('success', 'Lotação de estágio cadastrada com sucesso.')
    history.push(`/profissional/${profissional.id}/dados`)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissional.id}/dados`} />
      <Breadcrumb title='Cadastro de lotação de estágio' />
      <VFlow vSpacing={1.5}>
        <PageHeader title='Cadastro de lotação de estágio' subtitle={profissional.nome.titleCase()} />
        <EstagioForm onSubmit={handleSubmit} profissionalId={profissional.id} />
      </VFlow>
    </PageContent>
  )
}
