import useSession from 'components/auth/useSession'
import { FormApi } from 'final-form'
import { VisibilidadeLembreteEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { useEditableListField } from '../../EditableList'
import { LembreteForm, LembreteFormModel } from './components/LembreteForm'

interface AdicionarLembretesFormProps {
  name: MetaPath<LembreteFormModel>
  onCancel?()
}

export function AdicionarLembreteForm(props: AdicionarLembretesFormProps) {
  const { onCancel, name } = props
  const { handleSubmit } = useEditableListField({ name })
  const { getServerTimeNow } = useServerTime()
  const { isEstagio } = useSession()

  const onSubmit = (lembrete: LembreteFormModel, formApi: FormApi) => {
    handleSubmit({ ...lembrete, data: getServerTimeNow().getTime() }, formApi)
  }

  return (
    <LembreteForm
      initialValues={{
        _id: null,
        ativo: true,
        descricao: '',
        visibilidadeLembrete: VisibilidadeLembreteEnum.PUBLICO,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      hasCancelButton
      canChangeVisibility={!isEstagio}
    />
  )
}
