import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeader } from 'components/PageHeader'
import { useEstagioEditViewQuery, useUpdateEstagioMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useMemo } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router'

import { convertEstagioInputToModel } from '../converter'
import { EstagioFormModel } from '../model'
import { EstagioForm } from './EstagioForm'

interface UrlParams {
  profissionalId: ID
  estagioId: ID
}

export const EstagioUpdateView = () => {
  const alert = useAlert()
  const {
    params: { estagioId, profissionalId },
  } = useRouteMatch<UrlParams>()
  const history = useHistory()
  const handleRejection = useErrorHandler()

  const [updateEstagio] = useUpdateEstagioMutation()

  const {
    data: { estagio },
    loading,
  } = useEstagioEditViewQuery({
    variables: { id: estagioId },
  })

  const initialValues = useMemo(() => (estagio ? convertEstagioInputToModel(estagio) : null), [estagio])

  if (loading) return <LoadingIndicator />

  if (!estagio) return <Redirect to='/404' />

  if (!estagio.actions.permissaoEdicao.enabled) return <Redirect to='/403' />

  const handleUpdateEstagio = (values: EstagioFormModel) => {
    updateEstagio({
      variables: {
        input: {
          id: estagioId,
          instituicaoEnsino: values.instituicaoEnsino,
          perfisId: values.perfis.map((p) => p.id),
          dataInicioVigencia: values.periodoEstagio.startDate,
          dataFimVigencia: values.periodoEstagio.endDate,
        },
      },
    })
      .then(() => {
        alert('success', 'Lotação de estágio alterada com sucesso.')
        history.push(`/profissional/${profissionalId}/dados`)
      })
      .catch(handleRejection)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissionalId}/dados`} />
      <Breadcrumb title='Edição de lotação de estágio' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Edição de lotação de estágio' subtitle={estagio.profissional.nome} />
        <EstagioForm
          initialValues={initialValues}
          onSubmit={handleUpdateEstagio}
          edit
          profissionalId={profissionalId}
        />
      </VFlow>
    </PageContent>
  )
}
