/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon, Link, merge, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form, FormRenderProps } from 'components/form'
import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import { useFlags } from 'config/useFlagsContext'
import { useBuscaCidadaoListagemLazyQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import image from 'images/403-forbidden.png'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { isEmpty } from 'util/validation/Util'
import { LinkVerEquipesVinculadasView } from 'view/vinculacao-equipes/LinkVerEquipesVinculadasView'

import AcompanhamentoCondicaoSaudeForm from './AcompanhamentoCondicaoSaudeForm'
import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'
import AcompanhamentoCondicaoSaudeListing from './AcompanhamentoCondicaoSaudeListing'
import {
  AcompanhamentoSaudeRelatorioModal,
  RelatorioOperacionalAuthorization,
} from './components/AcompanhamentoSaudeRelatorioModal'
import convertModelToInput from './convertModelToInput'
import { DoSearchPanel } from './DoSearchPanel'
import { validate as validateForm } from './validator-acompanhamentoCondicaoSaude'

const GERAR_RELATORIO_MAX_ELEMENTS = 500

// TODO Royal Flush - Filtros Aplicados do Acompanhamento #20112
export default function AcompanhamentoCondicaoSaudeView() {
  const [currentFilter, setCurrentFilter] = useState({})
  const [executeQuery, { loading, data }] = useBuscaCidadaoListagemLazyQuery()
  const { hasAuthorization } = useSession()
  const { analytics } = useFirebase()
  const { ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED } = useFlags()
  const history = useHistory()

  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  const cidadaos = data?.acompanhamentoCondicaoSaude

  const permissions: RelatorioOperacionalAuthorization = {
    hasCriancaPermission: hasAuthorization(Permissions.relatorios.operacional.crianca),
    hasGestantePermission: hasAuthorization(Permissions.relatorios.operacional.gestante),
    hasHipertensaoPermission: hasAuthorization(Permissions.relatorios.operacional.riscoCardiovascular),
  }

  const canAcessarModalRelatorio =
    permissions.hasCriancaPermission || permissions.hasGestantePermission || permissions.hasHipertensaoPermission

  const disableGerarRelatorio =
    loading ||
    cidadaos?.pageInfo.totalElements > GERAR_RELATORIO_MAX_ELEMENTS ||
    cidadaos?.pageInfo.totalElements === 0 ||
    !canAcessarModalRelatorio

  const handleSubmit = (form: AcompanhamentoCondicaoSaudeFormModel) => {
    const hasPeriodoUltimoAtendimento =
      form?.periodoUltimoAtendimento?.startDate || form?.periodoUltimoAtendimento?.endDate

    const hasCiap = !isEmpty(form?.problemasCondicoes?.find((item) => isCiap(item)))
    const hasCid = !isEmpty(form?.problemasCondicoes?.find((item) => !isCiap(item)))

    !isEmpty(form.diabetesCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_diabetes')
    !isEmpty(form.hipertensaoCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_hipertensao')
    !isEmpty(form.obesidadeCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_obesidade')
    !isEmpty(form.gravidezCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_gravidez')
    !isEmpty(form.grupoCondicao) && analytics.logEvent('buscar_acomp_condicoes_saude_with_grupo_condicao')
    hasCiap && analytics.logEvent('buscar_acomp_condicoes_saude_with_ciap')
    hasCid && analytics.logEvent('buscar_acomp_condicoes_saude_with_cid')
    hasPeriodoUltimoAtendimento && analytics.logEvent('buscar_acomp_condicoes_saude_with_periodo_ultimo_atend')

    const input = convertModelToInput(form)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const onChangeFilter = (filter) => {
    const input = filter(currentFilter)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const renderForm = (formProps: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { values } = formProps
    const showTable = loading || !isUndefinedOrNull(cidadaos)

    return (
      <Fragment>
        <Breadcrumb title='Acompanhamento de condições de saúde' />
        <VFlow style={{ borderBottom: '1px solid lightgray' }}>
          <PageContent type='filled'>
            <TitleGroup title='Acompanhamento de condições de saúde' style={styles.titleAcompanhamento} />
            <AcompanhamentoCondicaoSaudeForm {...formProps} />
          </PageContent>
        </VFlow>
        <PageContent>
          <VFlow>
            {showTable ? (
              <Fragment>
                <TitleGroup title='Cidadãos encontrados' style={styles.titleCidadaosEncontrados} />
                <VFlow>
                  <LinkVerEquipesVinculadasView equipe={values?.equipeResponsavel} />
                  <AcompanhamentoCondicaoSaudeListing
                    onChange={onChangeFilter}
                    resultCidadaos={cidadaos}
                    loading={loading}
                  />
                </VFlow>
                <AcompanhamentoSaudeRelatorioModal
                  filter={merge({}, currentFilter, { pageParams: { page: 0, size: cidadaos?.pageInfo.totalElements } })}
                  disableGerarRelatorio={disableGerarRelatorio}
                  canAcessarModalRelatorio={canAcessarModalRelatorio}
                  permissions={permissions}
                />
              </Fragment>
            ) : (
              <DoSearchPanel isCoordenador={isCoordenador} equipeResponsavelId={values.equipeResponsavelId} />
            )}
          </VFlow>
        </PageContent>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED ? (
        <Form<AcompanhamentoCondicaoSaudeFormModel>
          render={renderForm}
          onSubmit={handleSubmit}
          validate={validateForm}
          initialValues={{ faixaEtariaFilter: AgeRangeFilter.TODAS_FAIXAS }}
        />
      ) : (
        <PageContent>
          <Grid style={{ paddingTop: '10rem' }} alignItems='center' justifyContent='space-around'>
            <Cell>
              <VFlow>
                <VFlow vSpacing={0.5}>
                  <h1>Estamos em manutenção</h1>
                  <p>Pedimos desculpas pelo imprevisto, mas não se preocupe, logo a situação será resolvida.</p>
                </VFlow>

                <p>
                  <Button kind='primary' onClick={history.goBack}>
                    <Icon icon='arrowLeft' style={{ marginRight: '0.25rem' }} />
                    <Text color='inherit'>Retornar à página anterior</Text>
                  </Button>
                </p>

                <p>
                  <Link component={RouterLink} to='/'>
                    Acessar página inicial
                  </Link>
                </p>
              </VFlow>
            </Cell>
            <Cell>
              <img
                src={image}
                alt='Duas pessoas olhando para uma porta protegida por um lacre, representando que o acesso está proibido'
              />
            </Cell>
          </Grid>
        </PageContent>
      )}
    </Fragment>
  )
}

const styles = {
  titleAcompanhamento: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `,
  titleCidadaosEncontrados: css`
    margin-bottom: 1rem;
  `,
}
